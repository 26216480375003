import { BaseService } from "@app/services/BaseService";
import { ModelCardInterface } from "@dashart/dashart-gpt-shared-library";
import { ChatServiceInterface } from "../interfaces/ChatServiceInterface";

/**
 * Service class for interacting with OpenRouter APIs.
 */
export class OpenRouterService extends BaseService implements ChatServiceInterface {

    /** Base name for OpenRouter models, used for history entries detection */
    readonly modelBaseNameStart: string = 'openrouter';

    /** Singleton instance of OpenRouterService */
    private static _instance: OpenRouterService;

    constructor() {
        super();
    }

    protected getConfiguration(): { defaultModel: string; apiResourceName: string; } {
        return {
            defaultModel: 'anthropic/claude-3-5-sonnet',
            apiResourceName: 'openrouter',
        }
    }

    /**
     * Gets the singleton instance of OpenRouterService.
     * @returns Singleton instance of OpenRouterService.
     */
    public static getInstance(): OpenRouterService {
        return this._instance ?? (this._instance = new OpenRouterService());
    }

    /**
     * Retrieves the available chat models.
     * @returns Promise resolving to an array of ModelCardInterface that match chat model criteria.
     */
    public async getAvailableChatModels(): Promise<ModelCardInterface[]> {
        return await this.getAvailableModels();
    }
}