import { ToastMessageInterface } from "@app/interfaces/ToastMessageInterface";
import { RoutesEnum } from "@app/models/RoutesEnum";
import { TokenUsageService } from "@app/services/TokenUsageService";
import { UserService } from "@app/services/UserService";
import { TokenUsageInterface } from "@dashart/dashart-gpt-shared-library/dist/index";
import UserInterface from "@dashart/dashart-gpt-shared-library/dist/interfaces/UserInterface";
import ButtonWithLink from "@view/components/buttonWithLink/ButtonWithLink";
import ProgressSpinnerOverlay from "@view/components/progressSpinnerOverlay/ProgressSpinnerOverlay";
import RoleValidatorComponent from "@view/components/roleValidatorComponent/RoleValidatorComponent";
import { DateTime } from "luxon";
// Import Chart.js first
import 'chart.js/auto';
import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import { Chart } from "primereact/chart";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { useDebounce } from "primereact/hooks";
import { Row } from "primereact/row";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import "./UserTokenUsageScreen.scss";

export interface UserTokenUsageScreenProps {
    currentUser: UserInterface;
    showToast: (toast: ToastMessageInterface) => void
}

interface TimePeriodOption {
    label: string;
    value: 'today' | 'yesterday' | 'this_week' | 'last_week' | 'this_month' | 'last_month';
}

const UserTokenUsageScreen: React.FunctionComponent<UserTokenUsageScreenProps> = (props: UserTokenUsageScreenProps) => {
    const { currentUser } = props;
    const { t } = useTranslation();
    const { userId } = useParams();

    const [userData, setUserData] = useState<UserInterface>(null);
    const [tokenUsageData, setTokenUsageData] = useState<TokenUsageInterface[]>([]);
    const [selectedTimePeriod, setSelectedTimePeriod] = useState<TimePeriodOption | null>(null);
    const [chartData, setChartData] = useState(null);
    const [chartOptions, setChartOptions] = useState(null);

    const [dateRange, dateRangeDebounced, setDateRange] = useDebounce([
        DateTime.now().minus({ days: 30 }).toJSDate(),
        DateTime.now().toJSDate(),
    ], 1000);
    const [serverActionPending, setServerActionPending] = useState(false);

    // Time period options for the dropdown
    const timePeriodOptions: TimePeriodOption[] = [
        { label: t("user_management.usage_screen.time_periods.today"), value: 'today' },
        { label: t("user_management.usage_screen.time_periods.yesterday"), value: 'yesterday' },
        { label: t("user_management.usage_screen.time_periods.this_week"), value: 'this_week' },
        { label: t("user_management.usage_screen.time_periods.last_week"), value: 'last_week' },
        { label: t("user_management.usage_screen.time_periods.this_month"), value: 'this_month' },
        { label: t("user_management.usage_screen.time_periods.last_month"), value: 'last_month' },
    ];

    const navigate = useNavigate();

    /**
     * Load user from backend
     * @param userId
     */
    const loadUser = async (userId: string) => {
        setServerActionPending(true);
        try {
            setUserData(await UserService.getOne(userId));
        } catch (error) {
            props.showToast({
                severity: "error",
                summary: t('globals.loadFailedNotice'),
                detail: JSON.stringify(error ?? '')
            });
            navigate(RoutesEnum.userList);
        }
        setServerActionPending(false);
    };

    /**
     * Load user from backend
     * @param userId
     */
    const loadUserTokenUsage = async (userId: string) => {
        setServerActionPending(true);

        const startTimeStamp = dateRange[0];
        const endTimeStamp = dateRange[1] ?? new Date();

        // start date should be start of day
        startTimeStamp.setHours(0, 0, 0, 0);
        // end date should be end of day
        endTimeStamp.setHours(23, 59, 59, 0);


        try {
            setTokenUsageData(await TokenUsageService.getUserTokenUsageByTimeRange(
                {
                    userId: +userId,
                    startTimeStamp: startTimeStamp.getTime(),
                    endTimeStamp: endTimeStamp.getTime(),
                }
            ));
        } catch (error) {
            props.showToast({
                severity: "error",
                summary: t('globals.loadFailedNotice'),
                detail: JSON.stringify(error ?? '')
            });
            navigate(RoutesEnum.userList);
        }
        setServerActionPending(false);
    };

    useEffect(() => {
        if (userId) {
            loadUser(userId);
        }
    }, [userId]);

    useEffect(() => {
        if (userData) {
            loadUserTokenUsage(
                userId
            );
        }
    }, [userData]);

    useEffect(() => {
        if (userData) {
            loadUserTokenUsage(
                userId
            );
        }
    }, [dateRangeDebounced]);

    // Prepare chart data when token usage data or date range changes
    useEffect(() => {
        prepareChartData();
    }, [tokenUsageData, dateRange, t]);

    const onDateRangeChange = (e: CalendarChangeEvent) => {
        setDateRange(e.value as [Date, Date]);
        setSelectedTimePeriod(null); // Reset selected time period when manually changing date range
    }

    // Set date range based on selected time period
    const setTimePeriod = (period: 'today' | 'yesterday' | 'this_week' | 'last_week' | 'this_month' | 'last_month') => {
        const now = DateTime.now();
        let startDate: DateTime;
        let endDate: DateTime = now;

        switch (period) {
            case 'today':
                startDate = now.startOf('day');
                break;
            case 'yesterday':
                startDate = now.minus({ days: 1 }).startOf('day');
                endDate = now.minus({ days: 1 }).endOf('day');
                break;
            case 'this_week':
                startDate = now.startOf('week');
                break;
            case 'last_week':
                startDate = now.minus({ weeks: 1 }).startOf('week');
                endDate = now.minus({ weeks: 1 }).endOf('week');
                break;
            case 'this_month':
                startDate = now.startOf('month');
                break;
            case 'last_month':
                startDate = now.minus({ months: 1 }).startOf('month');
                endDate = now.minus({ months: 1 }).endOf('month');
                break;
            default:
                startDate = now.minus({ days: 30 });
        }

        setDateRange([startDate.toJSDate(), endDate.toJSDate()]);
    }

    // Handle time period dropdown change
    const onTimePeriodChange = (option: DropdownChangeEvent) => {
        setSelectedTimePeriod(option.value);
        if (option) {
            setTimePeriod(option.value);
        }
    }

    const calculateTotalTokenCount = (data: TokenUsageInterface[]): {
        total: number,
        input: number,
        output: number
    } => {
        let total = 0;
        let inputTotal = 0;
        let outputTotal = 0;
        data.forEach((entry) => {
            total += entry.tokenCount;
            if (entry.tokenType === 'input') {
                inputTotal += entry.tokenCount;
            } else {
                outputTotal += entry.tokenCount;
            }
        });
        return {
            total: total,
            input: inputTotal,
            output: outputTotal
        };
    };

    // Group token usage data by date or time intervals
    const prepareChartData = () => {
        // Determine if we should group by hour or by day based on the date range
        const start = dateRange[0];
        const end = dateRange[1] || new Date();
        const daysInRange = Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
        const groupByHour = daysInRange <= 2; // Group by hour if range is 2 days or less

        // Generate all time intervals within the range
        const timeIntervals = [];
        const startDt = DateTime.fromJSDate(start);
        const endDt = DateTime.fromJSDate(end);

        if (groupByHour) {
            let currentHour = startDt.startOf('hour');
            while (currentHour <= endDt) {
                timeIntervals.push(currentHour.toFormat('yyyy-MM-dd HH:00'));
                currentHour = currentHour.plus({ hours: 1 });
            }
        } else {
            let currentDay = startDt.startOf('day');
            while (currentDay <= endDt) {
                timeIntervals.push(currentDay.toFormat('yyyy-MM-dd'));
                currentDay = currentDay.plus({ days: 1 });
            }
        }

        // Initialize data structures with all time intervals and zero counts
        const groupedData = {};
        const inputData = {};
        const outputData = {};

        timeIntervals.forEach(timeKey => {
            groupedData[timeKey] = 0;
            inputData[timeKey] = 0;
            outputData[timeKey] = 0;
        });

        // Process token usage data if available
        if (tokenUsageData && tokenUsageData.length > 0) {
            // Sort the data by createdAt date
            const sortedData = [...tokenUsageData].sort((a, b) => {
                return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
            });

            sortedData.forEach((entry) => {
                const date = DateTime.fromISO(entry.createdAt);
                let timeKey;

                if (groupByHour) {
                    timeKey = date.toFormat('yyyy-MM-dd HH:00');
                } else {
                    timeKey = date.toFormat('yyyy-MM-dd');
                }

                // Only add if the timeKey is within our range
                if (groupedData[timeKey] !== undefined) {
                    groupedData[timeKey] += entry.tokenCount;

                    if (entry.tokenType === 'input') {
                        inputData[timeKey] += entry.tokenCount;
                    } else {
                        outputData[timeKey] += entry.tokenCount;
                    }
                }
            });
        }

        // Prepare the labels (x-axis) and data (y-axis)
        const labels = timeIntervals.map(timeKey => {
            if (groupByHour) {
                return DateTime.fromFormat(timeKey, 'yyyy-MM-dd HH:00').toFormat('dd.MM HH:00');
            } else {
                return DateTime.fromFormat(timeKey, 'yyyy-MM-dd').toFormat('dd.MM');
            }
        });

        // Get the values from our maps in the same order as the labels
        const totalValues = timeIntervals.map(key => groupedData[key]);
        const inputValues = timeIntervals.map(key => inputData[key]);
        const outputValues = timeIntervals.map(key => outputData[key]);

        // Set chart data
        setChartData({
            labels: labels,
            datasets: [
                {
                    label: t('user_management.usage_screen.chart.total'),
                    backgroundColor: '#42A5F5',
                    borderColor: '#42A5F5',
                    data: totalValues
                },
                {
                    label: t('user_management.usage_screen.chart.input'),
                    backgroundColor: '#66BB6A',
                    borderColor: '#66BB6A',
                    data: inputValues
                },
                {
                    label: t('user_management.usage_screen.chart.output'),
                    backgroundColor: '#FFA726',
                    borderColor: '#FFA726',
                    data: outputValues
                }
            ]
        });

        // Set chart options with improved handling for many data points
        const isLargeDataset = labels.length > 20;

        setChartOptions({
            maintainAspectRatio: false,
            aspectRatio: 1.2,
            responsive: true,
            plugins: {
                legend: {
                    position: 'top'
                },
                tooltip: {
                    mode: 'index',
                    intersect: false
                }
            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text: groupByHour
                            ? t('user_management.usage_screen.chart.hour')
                            : t('user_management.usage_screen.chart.date')
                    },
                    ticks: {
                        maxRotation: 45,
                        minRotation: 45,
                        // Show fewer ticks when there are many data points
                        autoSkip: isLargeDataset,
                        maxTicksLimit: isLargeDataset ? 15 : undefined
                    }
                },
                y: {
                    title: {
                        display: true,
                        text: t('user_management.usage_screen.chart.token_count')
                    },
                    beginAtZero: true
                }
            }
        });
    };

    const timePeriodDropdown = (
        <div className="time-period-dropdown flex mb-2">
            <Dropdown
                value={selectedTimePeriod}
                options={timePeriodOptions}
                onChange={(e) => onTimePeriodChange(e)}
                optionLabel="label"
                placeholder={t("user_management.usage_screen.select_time_period")}
                className="w-full md:w-14rem"
            />
        </div>
    );

    const tableHeader = (
        <>
            <div className={'flex grid justify-content-end align-items-center'}>
                <div className={'mr-2 flex-grow-1'}>
                    {t("user_management.usage_screen.header", userData)}
                </div>
                <div className={'mr-2'}>
                    {t("user_management.usage_screen.date_range")}:
                </div>
                <Calendar
                    value={dateRange}
                    selectionMode="range" readOnlyInput
                    showButtonBar={true}
                    onChange={onDateRangeChange}
                />
                {timePeriodDropdown}
            </div>
            <div className="chart-container">
                {chartData && chartOptions ? (
                    <Chart
                        type="bar"
                        data={chartData}
                        options={chartOptions}
                        style={{ height: '200px', width: '100%' }}
                    />
                ) : (
                    <div className="no-data-message p-3 text-center">
                        {t("user_management.usage_screen.chart.no_data")}
                    </div>
                )}
            </div>
        </>
    );

    const tableSummaryFooter = (
        <ColumnGroup>
            <Row>
                <Column footer={`Total (${tokenUsageData?.length ?? 0})`} colSpan={3} />
                <Column footer={
                    t(
                        "user_management.usage_screen.table.summary_tokens",
                        calculateTotalTokenCount(tokenUsageData)
                    )
                } />
                <Column footer="" />
            </Row>
            <Row>
                <Column footer={
                    <div className={'flex grid justify-content-end align-items-center pt-1'}>
                        <ButtonWithLink
                            key={`btn-back`}
                            data-test-id={"btn-back"}
                            className="button-secondary"
                            label={t('globals.actions.backToOverview')}
                            icon="pi pi-chevron-left"
                            href={RoutesEnum.userList}
                        />
                    </div>
                } colSpan={5} />
            </Row>
        </ColumnGroup>
    );

    return <div className='UserTokenUsageScreen'>
        {/*Validate access rights*/}
        <RoleValidatorComponent
            currentUser={currentUser}
            validationType={'userScope'}
            userOfInterest={userData}
            redirectRouteIfNotAllowed={RoutesEnum.dashboard}
        />

        <ProgressSpinnerOverlay visible={serverActionPending} />

        <DataTable
            value={tokenUsageData}
            className={'m-2'}
            scrollable scrollHeight={'flex'}
            rowHover={true}
            header={tableHeader}
            footerColumnGroup={tableSummaryFooter as any}
        >
            <Column
                field="id"
                header={t("user_management.usage_screen.table.header_id")}
                sortable={true}
            />
            <Column
                field="modelId"
                header={t("user_management.usage_screen.table.header_model")}
                sortable={true} />
            <Column
                field="tokenType"
                header={t("user_management.usage_screen.table.header_tokenType")}
                sortable={true} />
            <Column
                field="tokenCount"
                header={t("user_management.usage_screen.table.header_tokenCount")}
                sortable={true}
            />

            <Column field="createdAt"
                header={t("user_management.usage_screen.table.header_createdAt")}
                body={(data) => {
                    return DateTime.fromISO(data.createdAt).toFormat('dd.MM.yy HH:mm:ss');
                }}
                sortable={true}
            />
        </DataTable>
    </div>
}

export default UserTokenUsageScreen;
