import { Dialog } from 'primereact/dialog';
import { ListBox, ListBoxChangeEvent } from 'primereact/listbox';
import React, { useEffect, useRef } from "react";

export interface SelectorDialogProps {
    header: string;
    description?: string;

    width: string;

    labelKey: string;
    availableValues: any[];
    selectedValues: any;

    onSelect: (model: any) => void;
    onclose: () => void;
}

export const SelectorDialogDialog: React.FunctionComponent<SelectorDialogProps> = (props: SelectorDialogProps) => {
    const { header, description, labelKey, availableValues: availableModels, selectedValues: selectedModel, onSelect, onclose, width } = props;
    const listBoxRef = useRef<ListBox>(null);

    const onSelectionChanged = (event: ListBoxChangeEvent) => {
        onSelect(event.value);
    }

    useEffect(() => {
        // Scroll to selected item when component mounts
        setTimeout(() => {
            if (listBoxRef.current) {
                const listBoxEl = listBoxRef.current.getElement();
                const selectedItem = listBoxEl.querySelector('.p-highlight');
                if (selectedItem) {
                    selectedItem.scrollIntoView({ block: 'nearest' });
                }
            }
        }, 100);
    }, []);

    return (
        <Dialog
            className="SelectorDialogDialog"
            header={header}
            visible={true}
            style={{ width: width }}
            onHide={onclose}
            closeOnEscape={true}
        >
            <div className="selector-dialog-content" style={{ display: 'flex', flexDirection: 'column', maxHeight: '70vh' }}>
                {description ? <p className='mt-0'>{description}</p> : null}
                <div style={{ flexGrow: 1 }}>
                    <ListBox
                        ref={listBoxRef}
                        value={selectedModel}
                        onChange={onSelectionChanged}
                        options={availableModels}
                        optionLabel={labelKey}
                        className="w-full"
                        style={{ maxHeight: '100%', overflow: 'auto' }}
                        listStyle={{ maxHeight: '50vh', overflow: 'auto' }}
                    />
                </div>
            </div>
        </Dialog>
    );
}

export default SelectorDialogDialog;
