// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserTokenUsageScreen{position:relative;width:100%;height:100%}.UserTokenUsageScreen .time-period-dropdown{margin-top:.5rem;justify-content:flex-end}.UserTokenUsageScreen .chart-container{padding:1rem;background-color:#fff;border-radius:4px;display:flex;align-items:center;justify-content:center}.UserTokenUsageScreen .no-data-message{color:#666;font-style:italic}.UserTokenUsageScreen .chart-summary .token-summary h3{margin-top:0;font-size:1.2rem}.UserTokenUsageScreen .p-tabview-panels{padding:1rem}`, "",{"version":3,"sources":["webpack://./src/view/screens/userTokenUsageScreen/UserTokenUsageScreen.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,UAAA,CACA,WAAA,CAEA,4CACE,gBAAA,CACA,wBAAA,CAGF,uCACE,YAAA,CACA,qBAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAGF,uCACE,UAAA,CACA,iBAAA,CAKE,uDACE,YAAA,CACA,gBAAA,CAKN,wCACE,YAAA","sourcesContent":[".UserTokenUsageScreen {\n  position: relative;\n  width: 100%;\n  height: 100%;\n\n  .time-period-dropdown {\n    margin-top: 0.5rem;\n    justify-content: flex-end;\n  }\n\n  .chart-container {\n    padding: 1rem;\n    background-color: #ffffff;\n    border-radius: 4px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n\n  .no-data-message {\n    color: #666;\n    font-style: italic;\n  }\n\n  .chart-summary {\n    .token-summary {\n      h3 {\n        margin-top: 0;\n        font-size: 1.2rem;\n      }\n    }\n  }\n\n  .p-tabview-panels {\n    padding: 1rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UserTokenUsageScreen": `UserTokenUsageScreen`,
	"time-period-dropdown": `time-period-dropdown`,
	"chart-container": `chart-container`,
	"no-data-message": `no-data-message`,
	"chart-summary": `chart-summary`,
	"token-summary": `token-summary`,
	"p-tabview-panels": `p-tabview-panels`
};
export default ___CSS_LOADER_EXPORT___;
