import { ModelCardInterface, VisionPromptContentImageInterface } from '@dashart/dashart-gpt-shared-library';
import ButtonWithIcon from "@view/components/buttonWithIcon/ButtonWithIcon";
import { CancelIcon } from "@view/components/icons/CancelIcon";
import { SendIcon } from "@view/components/icons/SendIcon";
import { InputTextarea } from "primereact/inputtextarea";
import * as React from 'react';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileInfoInterface } from '../../../app/interfaces/FileInfoInterface';
import ImagePromptUtil from '../../../app/utils/ImagePromptUtil';
import DirectoryReaderAsDialog from '../directoryReader/DirectoryReaderAsDialog';
import { FolderOpenIcon } from '../icons/FolderOpenIcon';
import { FolderPlusIcon } from '../icons/FolderPlusIcon';
import ImageFileSelector from '../imageFileSelector/ImageFileSelector';

interface ChatInputProps {
    model: ModelCardInterface;
    prompt: string;
    images: VisionPromptContentImageInterface[];
    isRunning: boolean;
    textAreaLines: number;
    selectedTextFiles?: FileInfoInterface[];

    onPromptChange: (prompt: string) => void;
    onImagesChange: (images: VisionPromptContentImageInterface[]) => void;

    onSelectedTextFilesChange: (selectedTextFiles: FileInfoInterface[] | null) => void;

    onSubmit: () => void;
    onCancel: () => void;
}

const ICON_SIZE = 24;

export const ChatInput = memo(({
    prompt,
    model,
    images,
    isRunning,
    textAreaLines,
    selectedTextFiles,
    onPromptChange,
    onImagesChange,
    onSelectedTextFilesChange,
    onSubmit,
    onCancel,
}: ChatInputProps) => {
    const { t } = useTranslation();

    const [selectFilesOverlayOpen, setSelectFilesOverlayOpen] = useState(false);

    const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && (event.metaKey || event.ctrlKey)) {
            onSubmit();
        }
    }, [onSubmit]);

    /**
     * Handle image change.
     * If the image is already in the list, replace it with the new image.
     * If the image is not in the list, add it.
     * @param currentImage 
     * @param newImage 
     */
    const handleImageChange = useCallback((currentImage: VisionPromptContentImageInterface, newImage: VisionPromptContentImageInterface | null) => {
        const newImages = [...images];

        if (currentImage) {
            // replace current image with new image
            const index = newImages.findIndex((image) => image.imageUrl === currentImage.imageUrl);
            if (index !== -1) {
                newImages[index] = newImage;
            } else {
                // add new image if it is not already in the list
                newImages.push(newImage);
            }
        } else {
            // add new image
            newImages.push(newImage);
        }

        onImagesChange(newImages);
    }, [onImagesChange, images]);

    /**
     * Handle paste event, add images to the list
     * @param event 
     * @returns 
     */
    const handlePaste = useCallback(
        async (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
            const clipboardItems = Array.from(event.clipboardData.items);
            const imageItems = clipboardItems.filter(item => item.type.indexOf('image') !== -1);

            if (imageItems.length === 0) {
                return;
            }

            // Prevent default paste only if we have images
            event.preventDefault();

            try {
                // Process all images in parallel
                const newImages = await Promise.all(
                    imageItems.map(async (item) => {
                        const blob = item.getAsFile();
                        if (!blob) {
                            return null;
                        }

                        return ImagePromptUtil.resizeImageFile(
                            blob,
                            ImagePromptUtil.IMAGE_CONFIG.MAX_WIDTH,
                            ImagePromptUtil.IMAGE_CONFIG.MAX_HEIGHT
                        );
                    })
                );

                // Filter out any null results and add to existing images
                const validNewImages = newImages.filter((img): img is VisionPromptContentImageInterface => img !== null);

                if (validNewImages.length > 0) {
                    onImagesChange([
                        ...(images ?? []),
                        ...validNewImages
                    ]);
                }
            } catch (error) {
                console.error('Error processing pasted images:', error);
            }
        },
        [images, onImagesChange]
    );

    /**
     * Render image selectors
     * @returns 
     */
    const renderImageSelectors = React.useMemo(() => {
        // render one empty if no images
        if (!images || images.length === 0) {
            return <ImageFileSelector
                dataTestId='add-image-selector'
                image={null}
                onChange={handleImageChange}
                width={24}
                height={24}
            />;
        }

        return images.map((image, index) => (
            <ImageFileSelector
                dataTestId='image-selector'
                key={index}
                image={image}
                onChange={handleImageChange}
                width={24}
                height={24}
            />
        ));
    }, [images, handleImageChange]);

    /**
     * Handle open of select files overlay
     */
    const handleSelectFilesOverlayOpen = useCallback(() => {
        setSelectFilesOverlayOpen(true);
    }, []);

    /**
     * Handle close of select files overlay
     */
    const handleSelectFilesOverlayClose = useCallback(() => {
        setSelectFilesOverlayOpen(false);
    }, []);

    /**
     * Update selected text files contents
     * @param fileContents 
     */
    const updateSelectedTextFiles = useCallback((selectedTextFiles: FileInfoInterface[] | null, closeDialog: boolean) => {
        onSelectedTextFilesChange(selectedTextFiles);
        if (closeDialog) {
            handleSelectFilesOverlayClose();
        }
    }, [onSelectedTextFilesChange, handleSelectFilesOverlayClose]);

    return (
        <div className="lower-section flex-grow-0 p-grid p-2 m-0 border-top-1">

            {selectFilesOverlayOpen && (
                <DirectoryReaderAsDialog
                    selectAllFilesOnDrop={true}
                    selectedTextFiles={selectedTextFiles}
                    onCloseRequest={handleSelectFilesOverlayClose}
                    setSelectedTextFiles={updateSelectedTextFiles}
                />
            )}

            <div className="grid" onKeyDownCapture={handleKeyDown}>
                <div className="col-12 p-1 pb-0 mb-1 relative">
                    <InputTextarea
                        className='col-12 p-2 pr-6 m-0'
                        placeholder={t('components.chat_input.your_prompt')}
                        rows={textAreaLines}
                        value={prompt}
                        onChange={(e) => onPromptChange(e.currentTarget.value)}
                        onPaste={handlePaste}
                    />
                    <div className='inputActionButtons absolute right-0 bottom-0 m-2'>
                        {model?.supportsVision && (
                            <div className='position-relative' style={{ height: '24px' }}>
                                <div className='flex flex-row gap-2 absolute top-0 right-0'>
                                    {renderImageSelectors}
                                </div>
                            </div>
                        )}
                        <ButtonWithIcon
                            icon={
                                !selectedTextFiles || selectedTextFiles.length === 0 ?
                                    <FolderPlusIcon width={ICON_SIZE} height={ICON_SIZE} data-testid="add-files-icon" /> :
                                    <FolderOpenIcon width={ICON_SIZE} height={ICON_SIZE} data-testid="update-files-icon" />
                            }
                            onClick={handleSelectFilesOverlayOpen}
                            aria-label={!isRunning ? 'add-files' : 'update-files'}
                        />
                        <ButtonWithIcon
                            icon={
                                !isRunning ?
                                    <SendIcon width={ICON_SIZE} height={ICON_SIZE} /> :
                                    <CancelIcon width={ICON_SIZE} height={ICON_SIZE} />
                            }
                            onClick={!isRunning ? onSubmit : onCancel}
                            aria-label={!isRunning ? 'send' : 'cancel'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});

ChatInput.displayName = 'ChatInput';

