import * as React from "react";
import styles from "./ChatMessagesList.module.scss";

import AutoScrollContainer from "@view/components/autoScrollContainer/AutoScrollContainer";
import { ChatCompletionRequestMessageRoleEnum } from "../../../app/hooks/useEnhancedChatGpt";
import { ChatMessageInterface } from "../../../app/interfaces/ChatMessageInterface";
import ChatMessage from "../chatMessage/ChatMessage";

export interface ChatMessagesListProps {
    /**
     * Custom class names
     */
    className?: string;

    /**
     * List of messages to render
     */
    messages: ChatMessageInterface[];

    /**
     * Flag if a completion is running
     */
    completionIsRunning: boolean;

    /**
     * Current question
     */
    currentQuestion?: string;

    /**
     * Current completion
     */
    currentCompletion?: string;

    /**
     * Current reasoning
     */
    currentReasoning?: string;

    /**
     * Label if no messages
     */
    labelIfNoMessages: string;

    /**
     * Callback when a fork request is made
     */
    onForkRequestHandler?: (messageIndex: number) => void;
}

/**
 * Render a list of chat messages in a scrollable container with auto scroll to bottom
 * 
 * @param props 
 * @returns 
 */
const ChatMessagesList: React.FunctionComponent<ChatMessagesListProps> = (props: ChatMessagesListProps) => {
    const {
        className,
        onForkRequestHandler,
        messages,
        completionIsRunning,
        currentQuestion,
        currentCompletion,
        currentReasoning,
        labelIfNoMessages
    } = props;
    const scrollToBottomTargetRef = React.useRef<HTMLDivElement>(null);

    const messagesCount = React.useMemo(() => {
        return messages.length;
    }, [messages]);

    /**
     * Render messages already completed,
     * this will increase performance for long conversations
     */
    const renderedCompletedMessage = React.useMemo(() => {
        return (messages ?? []).map((message, index) => <ChatMessage
            key={index}
            message={message}
            index={index}
            onForkRequestHandler={onForkRequestHandler}
        />)
    }, [messages])

    /**
     * Cached current question message rendering for performance reasons
     */
    const currentCompletionMessageRendered = React.useMemo(() => {
        if (!completionIsRunning) {
            return null;
        }
        return <ChatMessage
            message={{
                role: ChatCompletionRequestMessageRoleEnum.User,
                content: currentQuestion
            }}
            index={messages.length}
            onForkRequestHandler={onForkRequestHandler}
        />
    }, [completionIsRunning, currentQuestion, messages]);

    /**
     * Render messages list from top to bottom
     */
    const renderMessages = React.useMemo(() => {
        const messagesCount = messages.length;

        return <div className={'flex flex-column'}>
            {/* Render messages from top to bottom */}
            {renderedCompletedMessage}

            {/* Render current question if completion is running */}
            {currentCompletionMessageRendered}

            {/* Render current completion if completion is running */}
            {completionIsRunning ? <ChatMessage
                message={{
                    role: ChatCompletionRequestMessageRoleEnum.Assistant,
                    content: currentCompletion,
                    reasoning: currentReasoning
                }}
                index={messagesCount + 1}
                onForkRequestHandler={onForkRequestHandler}
            /> : null}
        </div>
    }, [messages, currentCompletion, currentReasoning, completionIsRunning, onForkRequestHandler, renderedCompletedMessage, currentCompletionMessageRendered]);

    return <AutoScrollContainer
        className={`${styles.ChatMessagesList} ${className}`}
        magicPullZoneHeight={30}
    >
        {renderMessages}

        {(messagesCount === 0 && !completionIsRunning) ? <div>
            <div className={'col-12'}>
                {labelIfNoMessages}
            </div>
        </div> : null}

        {/*auto scroll to bottom target*/}
        <div ref={scrollToBottomTargetRef} />
    </AutoScrollContainer>
}

export default ChatMessagesList;